'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HomePageMain = /** @class */function () {
    function HomePageMain() {
        this.programmeDom = document.getElementById("kingshiper-programme");
        this.init();
    }
    HomePageMain.prototype.init = function () {
        this.toggleTab();
    };
    // 解决方案
    HomePageMain.prototype.toggleTab = function () {
        var that = this;
        var programmeHeadListLis = _tool2.default.$_selectAll(this.programmeDom, 'ul.kingshiper-programme-headList li');
        programmeHeadListLis.forEach(function (item) {
            item.addEventListener('mouseenter', function () {
                programmeHeadListLis.forEach(function (pItem) {
                    return pItem.classList.remove('active');
                });
                item.classList.add('active');
                var className = item.getAttribute('data-type');
                var programmeContentListLi = _tool2.default.$_select(that.programmeDom, "ul.kingshiper-programme-contentList li.".concat(className));
                var programmeContentListLis = _tool2.default.$_selectAll(that.programmeDom, 'ul.kingshiper-programme-contentList li');
                programmeContentListLis.forEach(function (pItem) {
                    return pItem.classList.remove('active');
                });
                programmeContentListLi.classList.add('active');
                switch (className) {
                    case 'video':
                    case 'online':
                        that.programmeDom.classList.remove('blue-bg');
                        that.programmeDom.classList.remove('green-bg');
                        that.programmeDom.classList.add('purple-bg');
                        break;
                    case 'office':
                    case 'Data-Solutions':
                    case 'picture':
                    case 'system':
                        that.programmeDom.classList.remove('purple-bg');
                        that.programmeDom.classList.remove('green-bg');
                        that.programmeDom.classList.add('blue-bg');
                        break;
                    case 'audio':
                    case 'data':
                        that.programmeDom.classList.remove('purple-bg');
                        that.programmeDom.classList.remove('blue-bg');
                        that.programmeDom.classList.add('green-bg');
                        break;
                }
            });
        });
        // 设置初始状态
        var programmeHeadListFirstLi = _tool2.default.$_select(this.programmeDom, 'ul.kingshiper-programme-headList li:first-child');
        programmeHeadListFirstLi.classList.add('active');
        var programmeContentListFirstLi = _tool2.default.$_select(this.programmeDom, 'ul.kingshiper-programme-contentList li:first-child');
        programmeContentListFirstLi.classList.add('active');
        this.programmeDom.classList.add('green-bg');
    };
    return HomePageMain;
}();
exports.default = HomePageMain;